.container {
    text-align: center;
    /* margin-bottom: 2vh; */
}

.logo {
    padding: 1vh;
    background-color: #23283B;
}

.logo img {
    width: 40vw;
}

.poster {
    margin-top: 5vh;
}

.poster img {
    width: 80%;
    border: 5px solid #23283B;

}

.content {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'comic-sans';
    font-weight: 500;
    font-size: 4vh;
}

.flex{
    display: flex;
    justify-content: space-evenly;
    padding: 3vh;
}

.resume_button {
    background-color: #23283B;
    padding: 1rem;
    text-decoration: solid;
    border: 2px solid #23283B;
    border-radius: 10%;
    text-decoration: none;
    color: white;
    font-weight: 700;
    margin-bottom: 5vh;
}

.button:hover {
    background-color: #FE9800;
    border: 3px solid #23283B;
}